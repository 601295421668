import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor",
  viewBox: "0 0 80 80"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M39.52 13A26.5 26.5 0 1 0 66 39.5 26.53 26.53 0 0 0 39.52 13m0 50.63A24.13 24.13 0 1 1 63.65 39.5a24.16 24.16 0 0 1-24.13 24.13m6.08-33.37a1.18 1.18 0 0 0 .9.18 1.17 1.17 0 0 0 .75-.49 1.18 1.18 0 0 0 .19-.89 1.2 1.2 0 0 0-.5-.77 10.16 10.16 0 0 0-5.73-1.79c-4.61 0-8.71 3.18-10.47 8.11l-.11.33h-2.92a1.19 1.19 0 0 0 0 2.38h2.39l-.1.94q-.081.613-.09 1.23.007.623.09 1.24l.11 1h-2.4a1.19 1.19 0 1 0 0 2.38h2.91l.12.33c1.76 4.92 5.86 8.11 10.47 8.11a10.1 10.1 0 0 0 5.73-1.8 1.2 1.2 0 0 0 .5-.76 1.17 1.17 0 0 0-.19-.89 1.19 1.19 0 0 0-1.65-.31 7.63 7.63 0 0 1-4.39 1.38 8.83 8.83 0 0 1-7.72-5.34l-.35-.72h6.38a1.19 1.19 0 0 0 0-2.38h-7.05l-.06-.47a11 11 0 0 1 0-3.51l.06-.43h8.73a1.19 1.19 0 0 0 0-2.38h-8.06l.35-.72a8.83 8.83 0 0 1 7.71-5.34 7.64 7.64 0 0 1 4.4 1.38" }, null, -1)
  ])))
}
export default { render: render }